<template>
  <div>
    <div class="flex justify-start mr-10">
      <div class="functional-info">
        <div class="flex justify-between mb-5 text-secondary-one">
          <span class="fs-14 fw-600 text-primary-one">Contribution Profile</span>
        </div>
        <div class="flex justify-start items-center mb-5 fs-14">
          <span class="fun-title">Contribution Profile :</span><span class="fun-desc">Thought Leader</span>
        </div>
        <div class="flex justify-start items-center mb-5 fs-14">
          <span class="fun-title">Primary Focus :</span><span class="fun-desc">Digital Strategy and Transformation</span>
        </div>
        <div class="flex justify-start items-center mb-5 fs-14">
          <span class="fun-title">Funtional Area :</span><span class="fun-desc">Senior & Exec. Leadership</span>
        </div>
        <div class="flex justify-start items-center fs-14">
          <span class="fun-title">Intrest Area :</span><span class="fun-desc">Digitalization and Technology</span>
        </div>
      </div>
      <div class="biography">
        <div class="flex justify-between mb-5 pr-10">
          <span class="fs-14 fw-600 text-primary-one">Biography</span>
          </div>
        <div class="text-secondary-one">
          <p class="bio-desc w-full overflow-scroll bg-transparent i-mt-15" v-if="!editBio">{{ profileData.biography ? profileData.biography : '' }}</p>
          <textarea class="bio-desc w-full overflow-scroll bg-transparent i-mt-15" v-model="profileData.biography" v-else></textarea>
        </div>
      </div>
    </div>
    <!-- divider  -->
    <div class="divider-line-div bg-secondary-two mt-10 mr-10"></div>
    <!-- divider  -->
    <div class="justify-between mt-10">
        <div class="flex justify-between mb-5 capitalize">
          <span class="fs-18 fw-600 text-primary-one">Last Contribution</span>
        </div>
        <div class="contr-container grid grid-cols-3 gap-4 overflow-y-scroll ws-scrollbar mb-4">
          <div class="contr-item shadow-one rounded bg-primary-three mb-4" v-for="item in 8" :key="item">
            <div class="contr-title capitalize fs-14 fw-600 text-primary-one"><span>Blueprint Digital Transformation</span></div>
            <div class="divider-line-div bg-secondary-two my-2"></div>
            <div class="capitalize fs-12 text-secondary-two mb-2"><span>Corporate Maturity and Workforce Activation</span></div>
            <div class="fs-14 text-secondary-one mb-5"><span>A complete blueprint for corporate maturity and innovation. Including capabilities, tactices and metodologies ready to deploy in you company. </span></div>
            <div class="contr-status flex justify-between fs-14">
              <div class="flex items-center"><img class="mr-2" src="/images/icons/profile/inst_token.svg" alt=""><span>125 Tokens</span></div>
              <div class="flex items-center"><img class="mr-2" src="/images/icons/profile/eye.svg" alt=""><span>250 views</span></div>
              <div class="flex items-center"><img class="mr-2" src="/images/icons/profile/message_alt.svg" alt=""><span>2 comments</span></div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "PersonalProfile",
  data() {
    return {
      editBio: false
    }
  },
  props: ["profileData"],
  methods: {
    ...mapActions({ updateProfile : "profile/updateProfileData"}),
    updateBio() {
      this.updateProfile({biography: this.profileData.biography})
      this.editBio = !this.editBio
    }
  },
}
</script>
