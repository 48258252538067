var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex justify-start mr-10"},[_vm._m(0),_c('div',{staticClass:"biography"},[_vm._m(1),_c('div',{staticClass:"text-secondary-one"},[(!_vm.editBio)?_c('p',{staticClass:"bio-desc w-full overflow-scroll bg-transparent i-mt-15"},[_vm._v(_vm._s(_vm.profileData.biography ? _vm.profileData.biography : ''))]):_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.profileData.biography),expression:"profileData.biography"}],staticClass:"bio-desc w-full overflow-scroll bg-transparent i-mt-15",domProps:{"value":(_vm.profileData.biography)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.profileData, "biography", $event.target.value)}}})])])]),_c('div',{staticClass:"divider-line-div bg-secondary-two mt-10 mr-10"}),_c('div',{staticClass:"justify-between mt-10"},[_vm._m(2),_c('div',{staticClass:"contr-container grid grid-cols-3 gap-4 overflow-y-scroll ws-scrollbar mb-4"},_vm._l((8),function(item){return _c('div',{key:item,staticClass:"contr-item shadow-one rounded bg-primary-three mb-4"},[_vm._m(3,true),_c('div',{staticClass:"divider-line-div bg-secondary-two my-2"}),_vm._m(4,true),_vm._m(5,true),_vm._m(6,true)])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"functional-info"},[_c('div',{staticClass:"flex justify-between mb-5 text-secondary-one"},[_c('span',{staticClass:"fs-14 fw-600 text-primary-one"},[_vm._v("Contribution Profile")])]),_c('div',{staticClass:"flex justify-start items-center mb-5 fs-14"},[_c('span',{staticClass:"fun-title"},[_vm._v("Contribution Profile :")]),_c('span',{staticClass:"fun-desc"},[_vm._v("Thought Leader")])]),_c('div',{staticClass:"flex justify-start items-center mb-5 fs-14"},[_c('span',{staticClass:"fun-title"},[_vm._v("Primary Focus :")]),_c('span',{staticClass:"fun-desc"},[_vm._v("Digital Strategy and Transformation")])]),_c('div',{staticClass:"flex justify-start items-center mb-5 fs-14"},[_c('span',{staticClass:"fun-title"},[_vm._v("Funtional Area :")]),_c('span',{staticClass:"fun-desc"},[_vm._v("Senior & Exec. Leadership")])]),_c('div',{staticClass:"flex justify-start items-center fs-14"},[_c('span',{staticClass:"fun-title"},[_vm._v("Intrest Area :")]),_c('span',{staticClass:"fun-desc"},[_vm._v("Digitalization and Technology")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between mb-5 pr-10"},[_c('span',{staticClass:"fs-14 fw-600 text-primary-one"},[_vm._v("Biography")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-between mb-5 capitalize"},[_c('span',{staticClass:"fs-18 fw-600 text-primary-one"},[_vm._v("Last Contribution")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contr-title capitalize fs-14 fw-600 text-primary-one"},[_c('span',[_vm._v("Blueprint Digital Transformation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"capitalize fs-12 text-secondary-two mb-2"},[_c('span',[_vm._v("Corporate Maturity and Workforce Activation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fs-14 text-secondary-one mb-5"},[_c('span',[_vm._v("A complete blueprint for corporate maturity and innovation. Including capabilities, tactices and metodologies ready to deploy in you company. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contr-status flex justify-between fs-14"},[_c('div',{staticClass:"flex items-center"},[_c('img',{staticClass:"mr-2",attrs:{"src":"/images/icons/profile/inst_token.svg","alt":""}}),_c('span',[_vm._v("125 Tokens")])]),_c('div',{staticClass:"flex items-center"},[_c('img',{staticClass:"mr-2",attrs:{"src":"/images/icons/profile/eye.svg","alt":""}}),_c('span',[_vm._v("250 views")])]),_c('div',{staticClass:"flex items-center"},[_c('img',{staticClass:"mr-2",attrs:{"src":"/images/icons/profile/message_alt.svg","alt":""}}),_c('span',[_vm._v("2 comments")])])])
}]

export { render, staticRenderFns }