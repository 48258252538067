<template>
    <div class="relative flex">
        <!-- left part  -->
        <div class="i-left-panel shadow-one">
            <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">
            <div class="profile-summary">

                <div class="flex justify-center profile-pic-holder relative">
                    <img style="width: 111px;height: 127px" :src="profileData.image ? `${profileData.image}` : image_path"
                         alt="settings"/>

                    <img @click="onClickImage" class="propic-upload-button absolute cursor-pointer"
                         src="/images/icons/profile/upload.svg" alt="settings"/>

                    <input @change="onUploadFile($event)" ref="input" type="file" accept="image/*"
                           class="hidden">
                </div>

                <div class="text-center mt-5">
                    <p class="user-name fs-18 fw-600 text-primary-one">
                        {{ profileData.first_name ? profileData.first_name : 'N/A' }}
                        {{ profileData.last_name ? profileData.last_name : '' }}</p>
                    <p class="user-pos fs-14 text-secondary-one i-mt-10">{{
                            profileData.position && profileData.company ? profileData.position + ', ' + profileData.company : 'N/A'
                        }}</p>
                    <p class="user-com-location fs-12 text-secondary-one">{{
                            profileData.address && profileData.country ? profileData.address + ', ' + profileData.country : 'N/A'
                        }}</p>
                    <p class="user-connection fs-14 text-secondary-one i-mt-25">25,251 Connections</p>
                    <p class="user-conn-btn fs-14 fw-700 text-primary-one uppercase cursor-pointer">CONTACT
                        INFORMATION</p>
                </div>
                <!-- divider  -->
                <div class="divider-line-div bg-secondary-two mt-10"></div>
                <!-- divider  -->
                <div class="profile-side-box overflow-y-auto mt-10 fs-14 fw-600 text-primary-one capitalize">
                    <div class="flex justify-between cursor-pointer mb-8">
                        <router-link :to="{ name: 'workspace_dashboard' }"><h2 class="">workspace</h2></router-link>
                        <h2 class="">5</h2>
                    </div>
                    <div class="flex justify-between cursor-pointer mb-8">
                        <h2 class="">network</h2>
                        <h2 class="">25,211</h2>
                    </div>
                    <div class="flex justify-between cursor-pointer mb-8">
                        <h2 class="">contribution</h2>
                        <h2 class="">352.510</h2>
                    </div>
                    <div class="flex justify-between cursor-pointer mb-8">
                        <h2 class="">notifications</h2>
                        <h2 class="">22/<span>50</span></h2>
                    </div>
                </div>
            </div>
        </div>
        <!-- right part  -->
        <div class="i-right-panel">
            <div class="profile-heading-box flex flex-col i-pb-30">
                <workspace-menu :hide-project="true"/>
                <div class="flex items-center justify-between ws-select-dropdown ws-board-pos">
                    <div class="dashboard-area-selection flex justify-between">
                        <div class="cursor-pointer" v-for="(item, index) in dashAreaType" :key="index"
                             @click="selectDashboardType(index)">
                            <h2 class="fs-14 fw-600 text-primary-one"
                                :class="item.selected ? 'opacity-100' : 'opacity-50'">{{ item.title }}</h2>
                        </div>
                    </div>
                    <div class="flex relative">
                        <img src="/images/icons/message_icon.svg" alt="icon" class="cursor-pointer">
                        <img src="/images/icons/notification_icon.svg" alt="icon"
                             class="cursor-pointer i-ml-36" @click="notification = !notification">
                        <div class="absolute dotted-notification bg-primary-five" v-if="unreadNotification"></div>
                        <popup v-if="notification"/>
                    </div>
                </div>
            </div>
            <div class="pl-10 pr-8">
                <div class="loaded-dashborad">
                    <component :is="dashboardComponent" :profileData="profileData"></component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PersonalProfile from "@/components/profile/PersonalProfile";
import ProfileSettings from "@/components/profile/ProfileSettings";
import PlansNBillings from "@/components/profile/PlansNBillings";
import Contribution from "@/components/profile/Contribution";
import Popup from "../../../components/notification/Popup";
import UploadService from "@/store/services/uploadService";
import ProfileImage from "../../../assets/images/icons/profile/profile.png";
// import ProfileMenu from "../../../components/InstMenu/ProfileMenu";
export default {
    name: "Index",
    components: {Popup},
    // components: {ProfileMenu },
    created() {

    },
    data() {
        return {
            notification: false,
            dashboardComponent: PersonalProfile,
            dashAreaType: [
                {title: 'Profile', selected: true, component: PersonalProfile},
                {title: 'Settings', selected: false, component: ProfileSettings},
                {title: 'Plans & Billing', selected: false, component: PlansNBillings},
                {title: 'Contribution System', selected: false, component: Contribution}
            ],
            image_path: ProfileImage,
        }
    },
    computed: {
        ...mapGetters({
            profileData: "profile/data",
            notifications: 'notification/notifications',
        }),
        unreadNotification() {
            let unread = this.notifications.filter(item => !item.is_read);
            return unread && unread.length > 0;
        }
    },
    methods: {
        ...mapActions({fetchProfileData: "profile/fetchProfileData"}),
        selectType(index) {
            this.chatGroupType.forEach((element, key) => {
                key == index ? element.selected = true : element.selected = false
            });
        },
        selectDashboardType(index) {
            this.dashAreaType.forEach((element, key) => {
                key == index ? element.selected = true : element.selected = false
            });
            this.dashboardComponent = this.dashAreaType[index].component
        },

        onClickImage() {
            this.$refs.input?.click();
        },

        onUploadFile(e) {

            let uploadService = new UploadService(e);
            if (!uploadService.fileValidation(1024)) return false; //size KB
            let formData = uploadService.fieldAppend();

            const self = this;
            self.$store.dispatch("profile/updateProfileImage", formData).then(response => {
                if (response) {
                    this.fetchProfileData();
                } else {
                    alert('Something went wrong!!!');
                }
            });

        },
    },
    mounted() {
        this.fetchProfileData()
    }
}
</script>

<style scoped>
.loaded-dashborad {
    /* width: 1080px; */
    height: 812px;
    /* padding: 25px;
    background: #232326;
    box-shadow: 0px 15px 30px -15px rgba(0, 0, 0, 0.45); */
    border-radius: 2px;
}
</style>
