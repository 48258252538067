<template>
  <div class="pr-8">
    <div class="form-container">
      <div class="setting-form fs-14">
        <div class="text-sceondary-one">
          <div class="flex justify-items-stretch mb-6">
            <div class="form-group flex flex-col">
              <span class="i-mb-5">Current Plan</span>
              <input class="form-input text-secondary-two bg-transparent border-solid i-border-1 border-secondary-two rounded" type="text" value="Try and Explore" readonly>
            </div>
            <div class="form-group flex flex-col">
              <span class="i-mb-5">Due Date</span>
              <input class="form-input text-secondary-two bg-transparent border-solid i-border-1 border-secondary-two rounded" type="text" value="31-03-2022" readonly>
            </div>
          </div>
        </div>
      </div>   
    </div>

    <div class="fs-14 fw-600 text-primary-one plans-note-div">
      <span>Note: Your plan expires on 31-03-20222. Renew before to continue using Instrat360. Current token conversion rate is 10 Cent.  You can only convert by contributed earnings.</span>
    </div>
    
    <div class="plan-container">
      <div class="flex items-center mb-2">
        <h2 class="fs-14 fw-600 text-primary-one">Instrat Plans</h2>
        <img class="ml-3 cursor-pointer" src="/images/icons/profile/info-dark.svg" alt="">
      </div>
      <!-- divider  -->
      <div class="divider-line-div bg-secondary-two mb-8"></div>
      <!-- divider  -->
      <div class="plan-body">
        <div class="flex justify-between">
          <div class="plan-item-card bg-primary-three rounded shadow-one" v-for="(plan,index) in plans" :key="index">
            <div class="mb-8"><img :src="getImageUrl(plan.logo)" alt="link-icon"></div>
            <h2 class="fs-18 fw-600 uppercase mb-4 text-primary-one">{{ plan.title }}</h2>
            <h1 class="fs-14 fw-600 mb-4 text-primary-one">{{ plan.price }} <span>{{ plan.interval }}</span></h1>
            <h2 class="fs-18 fw-600 capitalize text-primary-one mb-6">{{ plan.main_feature }}</h2>
            <div v-if="plan.title != 'ENTERPRISE'">
              <p class="flex flex-row mb-5" v-for="(feature,findex) in plan.features" :key="findex">
                <img src="/images/icons/profile/circle_check_outline.svg" alt="link-icon">
                <span class="fs-14 ml-3 text-secondary-one">{{ feature }}</span>
              </p>
            </div>
            <div v-else>
              <h2 class="fs-18 fw-600 capitalize text-primary-one mb-6" v-for="(feature,findex) in plan.features" :key="findex">{{ feature }}</h2>
            </div>
            <div class="submit-div mt-8">
              <button class="fs-14 fw-600 cursor-pointer capitalize save shadow-one rounded "
                      :class="!plan.status ? 'bg-primary-three text-primary-one hover:bg-secondary-five'  : 'bg-primary-four text-primary-three'"
              >
                Choose</button>
            </div>
          </div>
        </div>
      </div>
    </div>
      
    </div>
</template>

<script>
export default {
  name: "PlansNBillings",
  data() {
    return {
      selectedType: 0,
      plans: [
          {title: 'TRY AND EXPLORE', price: 'FREE', interval: '', main_feature: 'Unlimited Exploration', features: ['1 Workspace', '1 Project', 'Unlimited team members', '1 Year License', 'Unlimited Cloud Storage'], logo: 'bulb.svg', status: true },
          {title: 'PERSONAL UNLIMITED', price: '20$', interval: 'per month', main_feature: 'Personal Advantage', features: ['1 Workspace', '1 Project', 'Unlimited team members', '1 Year License', 'Unlimited Cloud Storage'], logo: 'bulb.svg', status: false },
          {title: 'PROFESSIONAL', price: '250$', interval: 'per month', main_feature: 'Unlimited Value', features: ['1 Workspace', 'Unlimited Projects', 'Unlimited team members', '1000 free tokens', 'Unlimited Cloud Storage'], logo: 'case.svg', status: false },
          {title: 'ENTERPRISE', price: 'Contact us', interval: '', main_feature: 'Unlimited Value', features: ['Creating and Insentive', 'Model Extensive', 'Earning on', 'Contribution'], logo: 'legend.svg', status: false },
      ]
    }
  },
  methods: {
    getImageUrl(src) {
      return require('../../assets/images/icons/profile/'+ src);
    },
  },
}
</script>
