<template>
    <div class="flex justify-between">
        <div class="form-container">
            <div class="setting-form fs-14">
                <h2 class="fw-600 text-primary-one i-mb-10">Basic Information</h2>
                <!-- divider  -->
                <div class="divider-line-div bg-secondary-two mb-8"></div>
                <!-- divider  -->
                <div class="text-sceondary-one">
                    <div class="flex justify-items-stretch mb-6">
                        <div class="form-group flex flex-col">
                            <span class="i-mb-5">First Name:</span>
                            <input
                                class="form-input text-secondary-two bg-transparent border-solid i-border-1 border-secondary-two rounded"
                                type="text" placeholder="First name" v-model="profileData.first_name">
                        </div>
                        <div class="form-group flex flex-col">
                            <span class="i-mb-5">Last Name:</span>
                            <input
                                class="form-input text-secondary-two bg-transparent border-solid i-border-1 border-secondary-two rounded"
                                type="text" placeholder="Last name" v-model="profileData.last_name">
                        </div>
                    </div>
                    <div class="flex justify-items-stretch mb-6">
                        <div class="form-group flex flex-col">
                            <span class="i-mb-5">Occupation:</span>
                            <input
                                class="form-input text-secondary-two bg-transparent border-solid i-border-1 border-secondary-two rounded"
                                type="text" placeholder="Occupation" v-model="profileData.position">
                        </div>
                        <div class="form-group flex flex-col">
                            <span class="i-mb-5">Company:</span>
                            <input
                                class="form-input text-secondary-two bg-transparent border-solid i-border-1 border-secondary-two rounded"
                                type="text" placeholder="Company" v-model="profileData.company">
                        </div>
                    </div>
                    <div class="flex justify-items-stretch mb-6">
                        <div class="form-group flex flex-col">
                            <span class="i-mb-5">E-mail:</span>
                            <input
                                class="form-input text-secondary-two bg-transparent border-solid i-border-1 border-secondary-two rounded"
                                type="text" placeholder="Contact email" v-model="profileData.cont_email">
                        </div>
                        <div class="form-group flex flex-col">
                            <span class="i-mb-5">Phone:</span>
                            <input
                                class="form-input text-secondary-two bg-transparent border-solid i-border-1 border-secondary-two rounded"
                                type="text" placeholder="Phone" v-model="profileData.phone">
                        </div>
                    </div>
                    <div class="flex justify-items-stretch mb-6">
                        <div class="form-group flex flex-col">
                            <span class="i-mb-5">Address:</span>
                            <input
                                class="form-input text-secondary-two bg-transparent border-solid i-border-1 border-secondary-two rounded"
                                type="text" placeholder="Address" v-model="profileData.address">
                        </div>
                        <div class="form-group flex flex-col">
                            <span class="i-mb-5">City/Country:</span>
                            <input
                                class="form-input text-secondary-two bg-transparent border-solid i-border-1 border-secondary-two rounded"
                                type="text" placeholder="City/Country" v-model="profileData.country">
                        </div>
                    </div>
                </div>
            </div>
            <div class="setting-form fs-14">
                <h2 class="fw-600 text-primary-one i-mb-10">Account Settings</h2>
                <!-- divider  -->
                <div class="divider-line-div bg-secondary-two mb-8"></div>
                <!-- divider  -->
                <div class="text-sceondary-one">
                    <div class="flex justify-items-stretch mb-6">
                        <div class="form-group flex flex-col">
                            <span class="i-mb-5">Email:</span>
                            <input
                                class="form-input text-secondary-two bg-transparent border-solid i-border-1 border-secondary-two rounded"
                                type="text" placeholder="Email" v-model="profileData.email">
                        </div>
                        <div class="form-group flex flex-col">
                            <span class="i-mb-5">Current Password:</span>
                            <input
                                class="form-input text-secondary-two bg-transparent border-solid i-border-1 border-secondary-two rounded"
                                type="password" placeholder="" v-model="currentPassword">
                        </div>
                    </div>
                    <div class="flex justify-items-stretch mb-6">
                        <div class="form-group flex flex-col">
                            <span class="i-mb-5">New Password:</span>
                            <input
                                class="form-input text-secondary-two bg-transparent border-solid i-border-1 border-secondary-two rounded"
                                type="password" placeholder="" v-model="newPassword">
                        </div>
                        <div class="form-group flex flex-col">
                            <span class="i-mb-5">Retype Password:</span>
                            <input
                                class="form-input text-secondary-two bg-transparent border-solid i-border-1 border-secondary-two rounded"
                                type="password" placeholder="" v-model="confirmPassword">
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="checkbox"><input type="checkbox"><span class="roboto-normal fs-14 pl-2 grey">Keep me signed in</span></div> -->
            <div class="mt-12">
                <button
                    class="fs-14 fw-600 cursor-pointer capitalize save bg-primary-three shadow-one rounded text-primary-one hover:bg-secondary-five"
                    @click="updateProfileData">Save Changes
                </button>
                <!-- <button class="roboto-bold fs-14 ml-5 cursor-pointer uppercase cancel">Cancel</button> -->
            </div>
        </div>
        <div class="img-container">
            <div class="relative img-div">
                <img :src="profileData.image ? `${profileData.image}` : image_path" alt="">
                <div class="flex justify-between fs-14 fw-700 text-primary-two absolute i-ml-10 i-mr-10 img-text"
                     style="bottom: 16px">
                    <span class="">Asbjørn levring</span>
                    <span class="flex items-center">
                        <img class="mr-1" src="/images/icons/profile/info.svg"
                                                         alt=""> Info</span>
                </div>
            </div>
            <div class="flex justify-end i-mt-25">
                <label class="ws-settings-switch">
                    <input type="checkbox">
                    <span class="ws-settings-slider round"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ProfileImage from "@/assets/images/icons/profile/profile.png";

export default {
    name: "ProfileSettings",
    props: ["profileData"],
    data() {
        return {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            activeEmail: this.profileData.email,
            image_path: ProfileImage,
        }
    },
    computed:{
      ...mapGetters({
          profileData: "profile/data",
      })
    },
    methods: {
        ...mapActions({updateProfile: "profile/updateProfileData"}),
        getPbHeight(item) {
            return 90 * (item.pb / item.wp) + 'px';
        },
        getCbHeight(item) {
            return 90 * (item.cb / item.wp) + 'px';
        },
        
        updateProfileData() {
            let data = {}
            if (this.newPassword !== this.confirmPassword) {
                alert('Password not matched')
                return false
            }

            if (this.activeEmail != this.profileData.email) {
                if (!this.currentPassword) {
                    alert('Enter current password to change email')
                    return false
                }
                data.email = this.profileData.email
                data.currentPassword = this.currentPassword
            }

            if (this.currentPassword && this.newPassword) {
                data.currentPassword = this.currentPassword
                data.newPassword = this.newPassword
            }

            data.first_name = this.profileData.first_name
            data.last_name = this.profileData.last_name
            data.position = this.profileData.position
            data.company = this.profileData.company
            data.address = this.profileData.address
            data.country = this.profileData.country

            this.updateProfile(data)

        }
    }
}
</script>

<style scoped>
.ws-settings-switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 26px;
}

.ws-settings-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.ws-settings-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    -webkit-transition: .4s;
    transition: .4s;
    border: 1px solid #3D3D3D;
}

.ws-settings-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 3px;
    top: 2px;
    background-color: #3D3D3D;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .ws-settings-slider {
    background-color: transparent;
    border: 1px solid #2B80AD;
}

input:focus + .ws-settings-slider {
    box-shadow: 0 0 1px transparent;
}

input:checked + .ws-settings-slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
    left: 15px;
    background-color: #2B80AD;
}

.ws-settings-slider.round {
    border-radius: 16px;
}

.ws-settings-slider.round:before {
    border-radius: 50%;
}
</style>
