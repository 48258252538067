<template>
  <div class="pr-8">
    <div class="form-container">
      <div class="setting-form fs-14">
        <div class="text-sceondary-one">
          <div class="flex justify-items-stretch mb-6">
            <div class="form-group flex flex-col">
              <span class="i-mb-5">Current Plan</span>
              <input class="form-input text-secondary-two bg-transparent border-solid i-border-1 border-secondary-two rounded" type="text" value="Try and Explore" readonly>
            </div>
            <div class="form-group flex flex-col">
              <span class="i-mb-5">Due Date</span>
              <input class="form-input text-secondary-two bg-transparent border-solid i-border-1 border-secondary-two rounded" type="text" value="31-03-2022" readonly>
            </div>
          </div>
        </div>
      </div>   
    </div>

    <div class="fs-14 fw-600 text-primary-one plans-note-div">
      <span>Note: Your plan expires on 31-03-20222. Renew before to continue using Instrat360. Current token conversion rate is 10 Cent.  You can only convert by contributed earnings.</span>
    </div>
    
    <div class="plan-container">
      <div class="flex items-center mb-2">
        <h2 class="fs-14 fw-600 text-primary-one">Contribution</h2>
        <img class="ml-3 cursor-pointer" src="/images/icons/profile/info-dark.svg" alt="">
      </div>
      <!-- divider  -->
      <div class="divider-line-div bg-secondary-two mb-8"></div>
      <!-- divider  -->
      <div class="contribution-body">
        <div class="flex justify-between">
          <div class="contr-info contr-card bg-primary-three text-primary-one rounded shadow-one px-10 py-12">
            <h4 class="fs-16 mb-4">Your Balance</h4>
            <div class="flex justify-between">
              <h1 class="fs-40">3575 INST</h1>
              <img src="/images/icons/profile/inst_contr.png" alt="">
            </div>
            <p class="fs-14 mb-5">Current conversionrate 10 Cents</p>
            <p class="fs-14 mb-5">1231 is available by contribution INST </p>
            <p class="fs-14 mb-5">2344 was part of your initial puchase</p>
            <h2 class="fs-14 fw-700 mb-5">Convert now at  123,1 USD</h2>
            <h2 class="fs-18 fw-600 mb-5">Current Contribution Profile is calculated to <span class="text-primary-four">Expert Contributer</span>.</h2>
            <h2 class="fs-18 fw-600">35 Published Blueprint</h2>
          </div>
          <div class="contr-list contr-card bg-primary-three rounded shadow-one px-10 py-12">
            <div class="flex justify-between">
              <h4 class="fs-16">Your Activity</h4>
              <img class="cursor-pointer" src="/images/icons/profile/bar_chart_active.svg" alt="" v-if="chart" @click="chart = !chart">
              <img class="cursor-pointer" src="/images/icons/profile/bar_chart.svg" alt="" v-else @click="chart = !chart">
            </div>
            <div class="contr-chart" v-if="chart">
              <div class="chart-div mt-6 w-full relative">
                <img src="/images/profile/contr_chart.png" alt="">
                <div class="absolute top-0 left-0 w-full flex justify-between">
                  <div class="bar-cont flex justify-center items-end" v-for="(data, index) in chartdata" :key="index">
                    <div class="relative flex justify-center bg-primary-four text-secondary-one fs-12" :style="{'height': getHeight(data.earnings)}">
                      <span class="absolute bar-val">{{ data.earnings + ' Token' }}</span>
                      <span class="absolute bar-title">{{ data.month }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="contr-table" v-else>
              <table class="border-separate text-secondary-one fs-14 fw-400 w-full">
                <tr>
                  <td class="i-pb-15 border-solid i-border-b-1 border-secondary-two">Date</td>
                  <td class="i-pb-15 border-solid i-border-b-1 border-secondary-two">Name</td>
                  <td class="i-pb-15 border-solid i-border-b-1 border-secondary-two">Type</td>
                  <td class="i-pb-15 border-solid i-border-b-1 border-secondary-two">Puchases</td>
                  <td class="i-pb-15 border-solid i-border-b-1 border-secondary-two">Status</td>
                  <td class="i-pb-15 border-solid i-border-b-1 border-secondary-two">Earnings</td>
                </tr>
                <tr v-for="(item, index) in activities" :key="index" class="fw-600">
                  <td class="pb-5 border-solid i-border-b-1 border-secondary-four">{{ item.date }}</td>
                  <td class="pb-5 border-solid i-border-b-1 border-secondary-four">{{ item.name }}</td>
                  <td class="pb-5 border-solid i-border-b-1 border-secondary-four">{{ item.type }}</td>
                  <td class="pb-5 border-solid i-border-b-1 border-secondary-four">{{ item.purchases }}</td>
                  <td class="pb-5 border-solid i-border-b-1 border-secondary-four">{{ item.status }}</td>
                  <td class="pb-5 border-solid i-border-b-1 border-secondary-four">{{ item.earnings + ' token' }}</td>
                </tr>
                
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
      
    </div>
</template>

<script>
export default {
  name: "Contribution",
  data() {
    return {
      chart: false,
      activities: [
          {name: 'Digital Transformation', date: '01.02.2021', type: 'Blueprint', purchases: 250, earnings: 1000, status: true},
          {name: 'Digital Transformation', date: '01.02.2021', type: 'Blueprint', purchases: 550, earnings: 2000, status: true},
          {name: 'Digital Transformation', date: '01.02.2021', type: 'Blueprint', purchases: 350, earnings: 6000, status: true},
          {name: 'Digital Transformation', date: '01.02.2021', type: 'Blueprint', purchases: 200, earnings: 9000, status: true},
          {name: 'Digital Transformation', date: '01.02.2021', type: 'Blueprint', purchases: 400, earnings: 600, status: true},
      ],
      chartdata: [
          {month: 'Jan', earnings: 1000, status: true},
          {month: 'Feb', earnings: 2000, status: true},
          {month: 'Mar', earnings: 6000, status: true},
          {month: 'Apr', earnings: 9000, status: true},
          {month: 'May', earnings: 7000, status: true},
          {month: 'Jun', earnings: 600, status: true},
      ]
    }
  },
  methods: {
    getImageUrl(src) {
      return require('../../assets/images/icons/profile/'+ src);
    },
    getHeight(val) {
      let max = 10000
      return val/max * 100 + '%'
    }
  },
}
</script>

<style scoped>
table {
  border-spacing: 0 24px;
}
table .header td{
  border-bottom: 1px solid #222222;
}
</style>
