import axios from 'axios';

let config = {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
}

export default class UploadService {
    constructor(file) {
        this.file = file.target.files[0];
        this.formData = new FormData;
    }

    imagePreview() {
        return URL.createObjectURL(this.file);
    }

    fileValidation(size = 1024) {
        // let fileSize = size;
        // if (this.file.size / fileSize / fileSize > 1) {
        //     alert(`File max size must be ${fileSize}MiB`);
        //     return false;
        // }
        return true;
    }

    fieldAppend(data = {}) {
        let formData = new FormData();
        for (let field in data) {
            formData.append(field, data[field]);
        }
        formData.append('image_url', this.file);
        return formData;
    }

    fieldAppends(data, keys = null) {

        this.formData.append('file', this.file);

        if (!keys) {
            Object.keys(data).map(key => {
                this.formData.append(key, data[key]);
            });
            return this;
        }

        keys.map(key => {
            this.formData.append(key, data[key]);
        });

        return this;
    }

    fileSave(url, payload = null) {
        let data = payload || this.formData;
        return axios.post(url, data, config)
    }
}
